import React from "react";
import Section from "../../../containers/Section";
import GradientTitle from "../../GradientTitle";
import { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";
import cn from "classnames";
import Icon, { IconType } from "../../Icon";
import { GatsbyImage } from "gatsby-plugin-image";

interface ReportingProps {
  gradientTitle: string;
  gradientTitlePosition: number;
  subTitle: string;
  description: string;
  charts: any;
  productImage: any;
  productTitle: string;
  productPercentage: string;
}

const Reporting: React.FC<ReportingProps> = props => {
  const {
    gradientTitle,
    gradientTitlePosition,
    subTitle,
    description,
    charts,
    productImage,
    productTitle,
    productPercentage
  } = props;

  const [currentChart, setCurrentChart] = React.useState<number>(0);

  return (
    <Section className="flex flex-col lg:flex-row pt-32">
      <p className="lg:hidden pb-10 text-transparent font-semibold text-indigo-1">
        {subTitle}
      </p>
      <div className="relative lg:w-1/2">
        <div className="flex -mx-2">
          {charts?.map((item: any, i: number) => {
            const disabledColor = currentChart !== i && "text-gray-4-light";
            return (
              <div
                role="button"
                key={item.id}
                onClick={() => setCurrentChart(i)}
                className={cn(
                  "w-1/3 mx-2 p-4 bg-white rounded-md overflow-hidden relative border border-gray-4",
                  currentChart === i && "shadow-xl"
                )}
              >
                <div
                  className="h-1 w-full absolute left-0 top-[-1px]"
                  style={{
                    backgroundColor: currentChart !== i ? "#E2E8F0" : item.color
                  }}
                ></div>
                <p className={cn("text-base mt-2 text-gray-1", disabledColor)}>
                  {item.title}
                </p>
                <p className={cn("my-2 text-3xl font-semibold", disabledColor)}>
                  {item.price}
                </p>
                <p
                  className={cn(
                    "text-green-1 text-base flex items-center",
                    disabledColor
                  )}
                >
                  <Icon
                    className={cn(
                      "mr-1",
                      currentChart !== i ? "fill-gray-4-light" : "fill-green-1"
                    )}
                    iconType={IconType.arrowUp}
                  />
                  {item.percentage}
                </p>
              </div>
            );
          })}
        </div>
        <div className="border border-gray-4 rounded-lg mt-18 px-10 py-18 bg-white relative h-72">
          <img aria-hidden src={charts?.[currentChart]?.graph?.url}></img>
          <div className="border border-gray-4 rounded-2xl shadow-2xl px-4 py-6 flex items-center absolute top-0 left-4 -translate-y-1/2 bg-white">
            <Icon className="w-4" iconType={IconType.optimize} />
            <div className="border border-gray-4 py-3 px-1 mx-4 rounded-lg">
              <GatsbyImage image={productImage} alt="" aria-hidden />
            </div>
            <div className="flex flex-col justify-between">
              <p className="text-indigo-1 text-base mb-2 font-semibold">
                {productTitle}
              </p>
              <div className="flex items-center">
                <Icon className="fill-green-1" iconType={IconType.arrowUp} />
                <p className="text-base mx-3">{productPercentage}%</p>
                <div className="bg-gray-5 rounded-full h-[16px] w-[43px]"></div>
                <div className="bg-gray-3 rounded-full h-[16px] w-[43px] ml-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:pl-12 lg:text-left lg:w-1/2">
        <p className="hidden lg:block pb-10 text-transparent font-semibold text-indigo-1">
          {subTitle}
        </p>
        <GradientTitle
          title={gradientTitle}
          gradientPosition={gradientTitlePosition}
          variant={HeaderVariant.h2}
          color="purple"
          lineBreak={true}
          className="mt-14 lg:mt-0"
        />
        <Paragraph className="text-2xl font-semibold">{description}</Paragraph>
      </div>
    </Section>
  );
};

export default Reporting;
