import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Section from "../../../containers/Section";
import GradientTitle from "../../GradientTitle";
import Header, { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";

interface OptimizationSectionProps {
  gradientTitle: string;
  gradientTitlePosition: number;
  optimizations: any;
}

const Optimization: React.FC<OptimizationSectionProps> = props => {
  const { gradientTitle, gradientTitlePosition, optimizations } = props;

  return (
    <Section>
      <GradientTitle
        variant={HeaderVariant.h2}
        title={gradientTitle}
        gradientPosition={gradientTitlePosition}
        lineBreak={false}
        color="purple"
      />
      <div className="mt-22 grid grid-cols-1 lg:grid-cols-2 -mx-4">
        {optimizations?.map((optimization: any) => {
          return (
            <div
              key={optimization.id}
              className="mx-3 mb-10 last:mb-0 lg:mb-0 bg-gradient-to-br from-purple-3-light to-indigo-3-light rounded-lg shadow-xl p-6"
            >
              <div className="max-w-xl flex flex-col items-start">
                <div className="flex items-center justify-center p-4 bg-white shadow-2xl rounded-full">
                  <img src={optimization?.icon?.url} aria-hidden={true} />
                </div>
                <Header className="my-12" variant={HeaderVariant.h4}>
                  {optimization?.title}
                </Header>
                <Paragraph>{optimization?.description?.description}</Paragraph>
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default Optimization;
