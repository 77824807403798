import React from "react";
import Icon, { IconType } from "../../../Icon";

interface RecommendationProps {
  recommendation: any;
}

const Recommendation: React.FC<RecommendationProps> = props => {
  const { recommendation } = props;
  const { type, title, editText, inputs } = recommendation;

  switch (type) {
    case "advanced":
      return (
        <div
          className="mb-6 flex flex-row items-center justify-between border border-white bg-gray-5-light rounded-xl shadow-xl p-6"
          style={{
            width: "95%",
            maxWidth: "560px"
          }}
        >
          <div className="flex items-center">
            {inputs.slice(0, 3).map((input: any, i: number) => (
              <div className="flex flex-row items-center mr-2" key={input.key}>
                <div className="border border-gray-3 rounded-lg bg-white py-2 px-4">
                  {input.text}
                </div>
              </div>
            ))}
            <div className="flex">
              <Icon className="mr-1" iconType={IconType.minus} />
              <Icon iconType={IconType.add} />
            </div>
            {editText && (
              <div className="flex items-center ml-2">
                {editText}
                <Icon className="ml-2" iconType={IconType.edit} />
              </div>
            )}
          </div>
        </div>
      );
    default:
      return (
        <div
          className="mb-6 flex flex-row items-center bg-white rounded-xl shadow-xl p-6"
          style={{
            width: "95%",
            maxWidth: "560px"
          }}
        >
          <div className="p-3 bg-white shadow-2xl rounded-full mr-4">
            <Icon iconType={IconType.dspColor} />
          </div>
          <div className="flex flex-col items-start">
            <p
              className="text-lg font-semibold mb-4"
              style={{ maxWidth: "246px" }}
            >
              {title}
              <span className="text-indigo-1"> {}</span>
            </p>
            <div className="rounded-sm bg-gray-5-light w-72 h-3 mb-3" />
            <div className="rounded-sm bg-gray-5-light w-32 h-3" />
          </div>
        </div>
      );
  }
};

export default Recommendation;
