import { graphql } from "gatsby";
import React from "react";
import BottomCTA from "../components/BottomCta";
import HeroSection from "../components/HeroSection";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import { useSegment, trackView } from "../utils/analytics";
import { nav } from "../utils/navigation";
import { Name, Type, Location, Category, CTA_ID } from "../utils/analytics/constants";
import CreateOrders from "../components/DisplayAdvertising/CreateOrders";
import TargetMarket from "../components/DisplayAdvertising/TargetMarket";
import Optimization from "../components/DisplayAdvertising/Optimization";
import Reporting from "../components/DisplayAdvertising/Reporting";
import { IconType } from "../components/Icon";
import Bayer from "../components/Brands/BrandSvgs/bayer.svg";
import CalvinKlein from "../components/Brands/BrandSvgs/calvinklein.svg";
import Crocs from "../components/Brands/BrandSvgs/crocs.svg";
import DrTobias from "../components/Brands/BrandSvgs/drtobias.svg";
import FourSigmatic from "../components/Brands/BrandSvgs/foursigmatic.svg";
import HeroCosmetics from "../components/Brands/BrandSvgs/herocosmetics.svg";
import Lacoste from "../components/Brands/BrandSvgs/lacoste.svg";
import Umbra from "../components/Brands/BrandSvgs/umbra.svg";

interface DSPSelfServePageProps {
  data: any;
}

const brandsList = [
  {
    src: <Crocs />,
    filter: true
  },
  {
    src: <CalvinKlein />,
    filter: false
  },
  {
    src: <HeroCosmetics />,
    filter: false
  },
  {
    src: <Lacoste />,
    filter: false
  },
  {
    src: <FourSigmatic />,
    filter: false
  },
  {
    src: <Bayer />,
    filter: false
  },
  {
    src: <DrTobias />,
    filter: false
  },
  {
    src: <Umbra />,
    filter: false
  }
];

const DSPSelfServe: React.FC<DSPSelfServePageProps> = ({ data }) => {
  const { segment, helpers } = useSegment();

  const pageData = data?.allContentfulAmazonDspSelfServe?.nodes[0];

  return (
    <Layout primaryButtonId={CTA_ID.getStartedNav.id}>
      <SEO
        title={pageData?.seoSettings?.metadataTitle}
        description={pageData?.seoSettings?.metadataDescription}
        image={pageData?.seoSettings?.openGraphImage?.url}
        robots={pageData?.seoSettings?.robots}
      />
      <div className="space-y-20 lg:space-y-36">
        <HeroSection
          title={pageData?.mainTitle}
          h1Title={pageData?.h1Title}
          description={pageData?.mainDescription}
          cta={pageData?.mainCta}
          secondaryCta={pageData?.secondaryHeroCta}
          image={pageData?.mainImage?.gatsbyImageData}
          brandsList={brandsList}
          icon={IconType.dspColor}
        />
        <CreateOrders
          gradientTitle={pageData?.createOrdersGradientTitle}
          gradientTitlePosition={pageData?.createOrdersGradientTitlePosition}
          lottieJson={pageData?.createOrdersLottieJson?.internal?.content}
          selector={pageData?.createOrdersSelector}
        />
        <TargetMarket
          gradientTitle={pageData?.targetMarketsGradientTitle}
          gradientTitlePosition={pageData?.targetMarketsGradientPosition}
          subTitle={pageData?.targetMarketsSubTitle}
          description={
            pageData?.targetMarketsDescription?.targetMarketsDescription
          }
          recommendations={pageData?.targetMarketsUiJson?.cards}
        />
        <Optimization
          gradientTitle={pageData?.optimizationGradientTitle}
          gradientTitlePosition={pageData?.optimizationGradientPosition}
          optimizations={pageData?.optimizationCards}
        />
        <Reporting
          gradientTitle={pageData?.reportingGradientTitle}
          gradientTitlePosition={pageData?.reportingGradientPosition}
          subTitle={pageData?.reportingSubTitle}
          description={pageData?.reportingDescription?.reportingDescription}
          charts={pageData?.reportingProductChartSelector}
          productImage={pageData?.reportingProductImage?.gatsbyImageData}
          productTitle={pageData?.reportingProduct}
          productPercentage={pageData?.reportingProductPercentage}
        />
        <BottomCTA
          primaryButtonId={pageData?.bottomCta?.primaryButtonId}
          secondaryButtonId={pageData?.bottomCta?.secondaryButtonId}
          title={pageData?.bottomCta?.title}
          subtitle={pageData?.bottomCta?.subtitle}
          primaryButtonText={pageData?.bottomCta?.primaryButtonText}
          primaryButtonClassName={pageData?.bottomCta?.primaryButtonClassName}
          secondaryButtonClassName={
            pageData?.bottomCta?.secondaryButtonClassName
          }
          primaryButtonOnClick={() => {
            helpers
              .delayTrack(Name.Cta, {
                category: Category.ButtonClick,
                type: Type.Button,
                text: pageData?.bottomCta?.primaryButtonText,
                page_url: window?.location.href,
                destination: pageData?.bottomCta?.primaryButtonRoute,
                location: Location.BottomCta
              })
              .then(() => nav(pageData?.bottomCta?.primaryButtonRoute));
          }}
          secondaryButtonText={pageData?.bottomCta?.secondaryButtonText}
          secondaryButtonOnClick={() => {
            trackView(Name.Cta, {
              category: Category.ButtonClick,
              type: Type.Button,
              text: pageData?.bottomCta?.secondaryButtonText,
              page_url: window?.location.href,
              destination: pageData?.bottomCta?.secondaryButtonRoute,
              location: Location.BottomCta
            });
            nav(pageData?.bottomCta?.secondaryButtonRoute);
          }}
        />
      </div>
    </Layout>
  );
};

export default DSPSelfServe;

export const data = graphql`
  query ($language: String) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulAmazonDspSelfServe(
      filter: { node_locale: { eq: $language } }
    ) {
      nodes {
        seoSettings {
          metadataTitle
          metadataDescription
          openGraphImage {
            gatsbyImageData
            url
          }
          robots
        }
        h1Title
        bottomCtaDescription
        bottomCtaText
        createOrdersGradientTitle
        contentful_id
        createOrdersGradientTitlePosition
        createOrdersLottieJson {
          internal {
            content
          }
        }
        createOrdersSelector {
          icon {
            file {
              url
            }
          }
          description {
            description
          }
          title
        }
        growCard {
          description {
            description
          }
          icon {
            gatsbyImageData
            file {
              url
            }
          }
          title
        }
        growCardImage {
          file {
            url
          }
        }
        growGradientPosition
        growGradientTitle
        mainDescription
        mainImage {
          gatsbyImageData
        }
        mainTitle
        mainInputPlaceholder
        mainCta {
          text
          route
          disabled
          ctaId
        }
        secondaryHeroCta {
          text
          route
          disabled
          ctaId
        }
        optimizationGradientPosition
        optimizationGradientTitle
        optimizationCards {
          description {
            description
          }
          icon {
            url
          }
          title
        }
        reportingDescription {
          reportingDescription
        }
        reportingGradientPosition
        reportingGradientTitle
        reportingProduct
        reportingProductChartSelector {
          title
          color
          graph {
            url
          }
          percentage
          price
          id
        }
        reportingProductImage {
          gatsbyImageData
        }
        reportingProductPercentage
        reportingSubTitle
        targetMarketsGradientPosition
        targetMarketsGradientTitle
        targetMarketsSubTitle
        targetMarketsDescription {
          targetMarketsDescription
        }
        targetMarketsUiJson {
          cards {
            editText
            title
            type
            inputs {
              dropdown
              text
            }
          }
        }
        bottomCta {
          title
          subtitle
          primaryButtonId
          primaryButtonText
          secondaryButtonId
          secondaryButtonText
          primaryButtonRoute
          secondaryButtonRoute
          primaryButtonClassName
          secondaryButtonClassName
        }
      }
    }
  }
`;
