import React from "react";
import lottie from "lottie-web";
import GradientTitle from "../../../components/GradientTitle";
import Section from "../../../containers/Section";
import Header, { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";
import cn from "classnames";

interface CreateOrdersProps {
  gradientTitle: string;
  gradientTitlePosition: number;
  lottieJson: any;
  selector: any;
}

const CreateOrders: React.FC<CreateOrdersProps> = props => {
  const { gradientTitle, gradientTitlePosition, lottieJson, selector } = props;

  const [activeSelection, setActiveSelection] = React.useState<number>(0);

  const animationContainer = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    const anim = lottieJson && lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: JSON.parse(lottieJson != undefined && lottieJson),
      rendererSettings: {
        filterSize: {
          width: "200%",
          height: "200%",
          x: "-50%",
          y: "0%"
        }
      }
    });
    return () => anim.destroy(); // optional clean up for unmounting
  }, []);

  return (
    <Section className="grid grid-cols-1 lg:grid-cols-2">
      <div className="flex items-center order-last lg:order-first">
        <div className="w-full" ref={animationContainer}></div>
      </div>
      <div className="py-12 lg:pl-12 flex flex-col justify-center">
        <GradientTitle
          className="block"
          color="purple"
          lineBreak={true}
          variant={HeaderVariant.h2}
          title={gradientTitle}
          gradientPosition={gradientTitlePosition}
        />
        <div className="flex pt-4 pb-12 justify-start">
          {selector?.map((item: any, i: number) => {
            return (
              <button
                onClick={() => setActiveSelection(i)}
                className="flex items-center group max-w-xs mr-4"
                id={item.key}
              >
                {/* NEED TO FIGURE OUT HOVER EFFECT AND HOW SVG WILL TAKE FILL NOW THAT ITS COMING FROM CONTENTFUL */}
                <img
                  className={cn(
                    "flex p-3 rounded-full bg-white items-center mr-3",
                    i === activeSelection ? "shadow-xl" : "opacity-20",
                    "group-hover:opacity-100 group-hover:shadow-xl"
                  )}
                  src={item?.icon?.file?.url}
                  alt=""
                />
                <span
                  className={cn(
                    "bg-gradient-to-r text-left font-medium",
                    i !== activeSelection
                      ? "text-gray-3-light"
                      : "text-transparent bg-gradient-to-r from-purple-1 to-blue-1 bg-clip-text",
                    "group-hover:text-gray-3-dark"
                  )}
                >
                  {item.title}
                </span>
              </button>
            );
          })}
        </div>
        {selector && selector.length > 0 && (
          <>
            <Header
              className="pb-8 lg:w-1/2 max-w-sm mt-12 md:mt-0"
              variant={HeaderVariant.h3}
            >
              {selector[activeSelection]?.title}
            </Header>
            <Paragraph className="max-w-xl">
              {selector[activeSelection]?.description?.description}
            </Paragraph>
          </>
        )}
      </div>
    </Section>
  );
};

export default CreateOrders;
