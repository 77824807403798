import React from "react";
import { animated, config, useSprings } from "react-spring";
import Recommendation from "../Recommendation";

interface RecommendationSectionProps {
  recommendations: any;
}

const RecommendtionSection: React.FC<RecommendationSectionProps> = props => {
  const { recommendations } = props;
  const [recommendationSprings, set] = useSprings(
    recommendations ? recommendations?.length : 0,
    index => {
      return {
        from: { transform: `translate(${index % 2 === 0 ? 0 : -16}px, 0px)` },
        to: async next => {
          let count = 1;
          while (count <= 7) {
            await next({
              transform: `translate(${index % 2 === 0
                ? count % 2 === 0
                  ? -16
                  : 0
                : count % 2 === 0
                  ? 0
                  : -16
                }px, ${count * -137}px)`
            });
            count = count + 1;
          }
        },
        loop: true,
        config: {
          ...config.gentle,
          duration: 6000
        }
      };
    }
  );

  return (
    <div className="h-full overflow-hidden flex justify-center">
      <div
        className="flex absolute flex-col items-center space-y-3 overflow-y-hidden lg:right-2 lg:items-end"
        style={{
          maxHeight: "774px"
        }}
      >
        {recommendations &&
          recommendationSprings?.map((props, idx) => {
            const recommendation = recommendations[idx];
            return (
              <animated.div
                key={idx}
                className="w-full flex justify-end"
                style={props}
              >
                <Recommendation recommendation={recommendation} />
              </animated.div>
            );
          })}
      </div>
      <div
        className="absolute top-0 w-full h-14 md:h-32 lg:h-48"
        style={{
          background:
            "linear-gradient(180deg, #F8FAFC 0%, rgba(248, 250, 252, 0) 100%)"
        }}
      />
      <div
        className="absolute bottom-0 w-full h-14 md:h-32 lg:h-48"
        style={{
          background:
            "linear-gradient(180deg, rgba(248, 250, 252, 0) 0%, #F8FAFC 100%)"
        }}
      />
    </div>
  );
};

export default RecommendtionSection;
