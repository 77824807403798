import React from "react";
import Section from "../../../containers/Section";
import GradientTitle from "../../GradientTitle";
import { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";
import RecommendationSection from "./RecommendationSection";

interface TargetMarketProps {
  gradientTitle: string;
  gradientTitlePosition: number;
  subTitle: string;
  description: string;
  recommendations: any;
}

const TargetMarket: React.FC<TargetMarketProps> = props => {
  const {
    gradientTitle,
    gradientTitlePosition,
    subTitle,
    description,
    recommendations
  } = props;

  return (
    <div className="relative bg-gray-6-light rounded-tl-large">
      <Section className="flex items-center flex-col lg:flex-row">
        <div className="py-32 max-w-xl lg:text-left w-full lg:flex-grow">
          <p className="pb-10 text-transparent font-semibold text-indigo-1">
            {subTitle}
          </p>
          <GradientTitle
            title={gradientTitle}
            gradientPosition={gradientTitlePosition}
            variant={HeaderVariant.h2}
            color="purple"
            lineBreak={false}
          />
          <Paragraph className="text-2xl font-semibold">
            {description}
          </Paragraph>
        </div>
        <div className="relative mx-auto w-full max-w-2xl overflow-y-hidden h-[660px] lg:h-full lg:min-h-[660px] lg:w-1/2">
          <RecommendationSection recommendations={recommendations} />
        </div>
      </Section>
    </div>
  );
};

export default TargetMarket;
